import pkg from '../../../package.json';

/////////////////////////////////           LOGIN APIs            //////////////////////////////////////////
export const kycpanal1loginApi = `${pkg.baseUrl}/kycPanel1/login`;
export const kycpanal2loginApi = `${pkg.baseUrl}/kycPanel2/login`;
export const jobassistantloginApi = `${pkg.baseUrl}/jobAssistant/login`;
export const complaintloginApi = `${pkg.baseUrl}/help/login`;
export const helploginApi = `${pkg.baseUrl}/adminPanal/login`;
export const adminpanalloginApi = `${pkg.baseUrl}/adminPanal/login`;
export const parivahanLoginApi = `${pkg.baseUrl}/riyoparivahan/login`

///////////////////////////////             DASHBOARD APIs         //////////////////////////////////////////
// export const kycpanal1dashboard = 'http://localhost:3000/dashboard';
// export const adminpanaldashboard = `${pkg.baseUrl}/kycPanel1/dashboard`;
// export const kycpanal2dashboard = `${pkg.baseUrl}/kycPanel2/dashboard`;
// export const jobassistantdashboard = `${pkg.baseUrl}/jobAssistant/dashboard`;
// export const helpdashboard = `${pkg.baseUrl}/help/dashboard`;
// export const complaintdashboard = `${pkg.baseUrl}/complaint/dashboard`;