import React, { useRef, useState } from "react";
import logo from "../../assets/logo.svg";
import bg from "../../assets/bg.svg";
import user from "../../assets/user.svg";
import passwardicon from "../../assets/passward.svg";
import text from "../../assets/text.png";
import "./LoginPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { adminpanalloginApi, kycpanal1loginApi, parivahanLoginApi } from "../../constants/api/APIs";
import base64 from 'base-64';

const LoginPage = () => {
  const username = useRef();
  const password = useRef();
  const userType = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  let kycUrl = new URL("https://kycpanel.riyorentitoverindia.in")
  let parivahanUrl = new URL("https://parivahan-manager.riyorentitoverindia.in")
  // let parivahanUrl = new URL("https://deploy-preview-6--bright-torrone-923830.netlify.app/")

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!username.current.value || !password.current.value) {
      alert('username or password should  not be empty')
      return;
    }
    setLoading(true)
    try { 

      let data = {
        userName: username.current.value,
        password: password.current.value
      }
      let api = adminpanalloginApi;

      if (userType.current.value === 'kyc 1') {
        api = kycpanal1loginApi;
      } else if (userType.current.value === 'parivahan manager') {
        api = parivahanLoginApi
      }
      const response = await axios.post(api, data)
      if (userType.current.value === 'kyc 1') {
        let token = base64.encode(response.data.token)
        kycUrl.searchParams.append("searchuser", token)
        window.location.href = kycUrl.toString()
      }
      console.log("user type", userType)
      if (userType.current.value === 'parivahan manager') {
        let token = base64.encode(response.data.token)
        parivahanUrl.searchParams.append("searchuser", token)
        window.location.href = parivahanUrl.toString()
      }
      setLoading(false)
    }
    catch (error) {
      setLoading(false)
      alert('Invalid Credentials')
      console.log(error)
    }
  };

  return (
    <div className="Login-page">
      <div className="left">
        <img className="text" src={text} alt="" />
        <img className="bg" src={bg} alt="" />
      </div>
      <div className="right" >
        <form className="login-form" onSubmit={handleLogin}>
          <img className="logo" src={logo} alt="" />
          <div className="welcome">Welcome!</div>
          <div className="select-field">
            <select name="user-type" className="select" ref={userType} >
              <option value="admin panal">--Select User Type--</option>
              <option value="kyc 1">KYC 1</option>
              <option value="parivahan manager">PARIVAHAN MANAGER</option>
              {/* <option value="kyc 2">KYC 2</option>
              <option value="job assistant">JOB ASSISTANT</option>
              <option value="complaint">COMPLAINT</option>
              <option value="help">HELP</option> */}
            </select>
          </div>
          <div className="input-field">
            <img className="icon" src={user} alt="" />
            <input type="text" placeholder="User Name" ref={username} />
          </div>
          <div className="input-field">
            <img className="icon" src={passwardicon} alt="" />
            <input type="password" placeholder="Password" ref={password} />
          </div>
          <button type="submit">Login</button>
          {loading && (
            <div className="loading-overlay">
              <i className="fa fa-spinner fa-spin" style={{ fontSize: "48px", color: "var(--primary)" }}></i>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
